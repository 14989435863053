import { /*Store, */ Module, ActionContext, ActionTree, MutationTree, GetterTree } from "vuex";
import { IRootState } from "@/store";
import utils from "@/shared/utils";
import api from "./apiCalls";
import { User, IUser, IUserTeam } from "@/shared/user";

export const state: ILoginState = {
  user: undefined,
  redirect: undefined,
  username: undefined,
  isSSO: false,
  teams: undefined,
  loginLoading: false,
};
export interface ILoginState {
  username?: string;
  redirect?: object;
  user?: IUser;
  isSSO: boolean;
  teams?: IUserTeam[];
  loginLoading: boolean;
}

const actions: ActionTree<ILoginState, IRootState> = {
  signUpUser({}: any, spec: Object): Promise<object> {
    return new Promise((resolve, reject) => {
      api
        .signUpUser(spec)
        .then(resolve)
        .catch((error: object) => {
          console.error("signUpUser error", error);
          reject(error);
        });
    });
  },
  verifyUser({}: any, spec: Object): Promise<object> {
    return new Promise((resolve, reject) => {
      api
        .verifyUser(spec)
        .then(resolve)
        .catch((error: object) => {
          console.error("verifyUser error", error);
          reject(error);
        });
    });
  },
  getJWT(store: ActionContext<ILoginState, any>, payload: { apiKey: string; accountId?: string; teamId?: string }): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!payload.accountId) {
        console.error("getJWT error", "missing accountId");
        reject(new Error("missing accountId"));
        return;
      }
      if (!payload.apiKey) {
        console.error("getJWT error", "missing apikey");
        reject(new Error("missing apikey"));
        return;
      }
      const successHandler: (data: any) => void = (data: any) => {
        if (data && data.jwt) {
          if (!payload.teamId) {
            utils.setStorage("jwt-auth", "jwt-token " + data.jwt);
          } else {
            utils.setStorage(`jwt-team-${payload.teamId}`, "jwt-token " + data.jwt);
          }
          const cookies = $cookies;
          if (!payload.teamId && cookies) {
            // this JWT does not contain a team on it and is used only to get teams list
            cookies.set("jwt-auth", "jwt-token " + data.jwt);
          }
          if (cookies && payload.teamId) {
            cookies.set("teamid", payload.teamId);

            // we have a header/cookie limit set on server side, so we limit JWT cookies to 1 to be safe under the limit of 8KB
            utils.clearTeamTokenCookies();

            cookies.set(`jwt-team-${payload.teamId}`, "jwt-token " + data.jwt);
            cookies.set("user", payload.accountId);
          }
          resolve(data);
          return;
        }
        console.error("getJWT error", "empty response", data);
        reject(new Error("empty response"));
      };
      api
        .getJWT(payload.accountId, payload.apiKey, payload.teamId)
        .then(successHandler)
        .catch((error: object) => {
          console.error("getJWT error", error);
          reject(error);
        });
    });
  },
  async logout(store: ActionContext<ILoginState, any>) {
    // important await to avoid racing condition
    await api.revokeJWT();
    sessionStorage.clear();
    localStorage.clear();
    store.commit("UserName", undefined);
    store.commit("User", undefined);
    store.commit("Redirect", undefined);
    store.commit("LoginLoading", undefined);
    store.commit("Teams", undefined);
    utils.clearStorage();
  },
  setShowModelInsightInfo(store: ActionContext<ILoginState, any>, show: boolean): void {
    store.commit("ShowModelInsightInfo", show);
  },
  setUserName(store: ActionContext<ILoginState, any>, username: string): void {
    store.commit("UserName", username);
  },
  setRedirect(store: ActionContext<ILoginState, any>, query: any): void {
    // separates path from query params and create a vue-router friendly redirect object to store
    const [redirectPath, redirectQueryString] = (query.redirect as string)?.split("?");
    let redirectQueryParamsStrList;
    const redirectQueryParams: any = {};
    if (redirectQueryString?.length) {
      redirectQueryParamsStrList = redirectQueryString?.split("&");
      redirectQueryParamsStrList.forEach((param) => {
        const [name, value] = param.split("=");
        redirectQueryParams[name] = value;
      });
    }
    // specific checks for account insights page query params - important!
    if (query.accountId) {
      redirectQueryParams.accountId = query.accountId;
    }
    if (query.modelId) {
      redirectQueryParams.modelId = query.modelId;
    }
    if (query.tld) {
      redirectQueryParams.tld = query.tld;
    }
    const redirect = {
      path: redirectPath as string,
      query: redirectQueryParams,
    };
    store.commit("Redirect", redirect);
  },
  unsetRedirect(store: ActionContext<ILoginState, any>): void {
    store.commit("Redirect", undefined);
  },
  setIsSSO(store: ActionContext<ILoginState, any>, flag: boolean): void {
    store.commit("IsSSO", flag);
  },
  setUser(store: ActionContext<ILoginState, any>, user: IUser): void {
    store.commit("User", user);
  },
  setLoginLoading(store: ActionContext<ILoginState, any>, flag: boolean): void {
    store.commit("LoginLoading", flag);
  },
  checkSSO({}: any, email: string): Promise<object> {
    return api.checkSSO(email);
  },
  startSSO({}: any, email: string): Promise<object> {
    return api.startSSO(email);
  },
  verifyIdToken({}: any, token: string): Promise<object> {
    return api.verifyIdToken(token);
  },
  authenticateOpenIDUser(store: ActionContext<ILoginState, any>, user: IUser): Promise<boolean> {
    return new Promise((resolve, reject) => {
      if (user.access_plan && user.access_plan.is_mui === "True") {
        const cookies = $cookies;
        if (cookies) {
          const loggedinuser: IUser = {
            api_key: user.api_key ? user.api_key : "",
            id: user.id ? user.id : "",
          };
          cookies.set("loggedinuser", loggedinuser);
        }
        store.commit("User", user);
        resolve(true);
      } else {
        reject(new Error("authenticateOpenIDUser"));
      }
    });
  },
  tryLogin(store: ActionContext<ILoginState, any>, payload: { email: string; password: string }): Promise<object> {
    const cookies = $cookies;
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("tryLogin error", error);
        reject(error);
      };
      const successHandler: (data: object) => void = (data: object) => {
        const user: IUser = data as IUser;
        if (user) {
          if (cookies) {
            const loggedinuser: IUser = {
              api_key: user.api_key ? user.api_key : "",
              id: user.id ? user.id : "",
            };
            cookies.set("loggedinuser", loggedinuser);
          }
          store.commit("User", user);
          resolve(user);
          return;
        }
        console.error("tryLogin error", "response is not IUser", data);
        reject(new Error("response is not IUser"));
      };

      if (!payload.email || !payload.password) {
        errorHandler({ error: "empty login/password" });
        return;
      }
      api.tryLogin(payload.email, payload.password).then(successHandler).catch(errorHandler);
    });
  },
  resetPassword(
    {}: any, // this method doesn't need access to the local store
    email: string,
  ): Promise<object> {
    return new Promise((resolve: (data: any) => void, reject: (data: any) => void) => {
      api
        .resetPassword(email)
        .then(resolve)
        .catch((error: any) => {
          console.error("resetPassword error", error.response);
          const errorObj: object = error.response ? error.response : error;
          reject(errorObj);
        });
    });
  },
  setPassword(
    {}: any, // this method doesn't need access to the local store
    payload: {
      tokenId: string;
      email: string;
      password: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("setPassword error", error);
        reject(error);
      };

      if (!payload.email || !payload.password) {
        errorHandler({ error: "empty login/password" });
        return;
      }

      api.setPassword(payload.tokenId, payload.email, payload.password).then(resolve).catch(errorHandler);
    });
  },
  verifyPasswordToken(
    {}: any,
    payload: {
      tokenId: string;
      email: string;
    },
  ): Promise<object> {
    return new Promise((resolve, reject) => {
      const errorHandler: (error: object) => void = (error: object) => {
        reject(error);
      };

      if (!payload.email || !payload.tokenId) {
        errorHandler({ error: "Incorrect email or token" });
        return;
      }

      api.verifyPasswordToken(payload.tokenId, payload.email).then(resolve).catch(errorHandler);
    });
  },
  getUserTeams(store: ActionContext<ILoginState, any>, payload: { accountId: string; setUser?: boolean; apiKey?: string }): Promise<IUserTeam[]> {
    return new Promise((resolve, reject) => {
      const successHandler: (data: any) => void = (data: any) => {
        const is_enlyft_admin: boolean = data.is_enlyft_admin as boolean;
        const teams: IUserTeam[] = data.accounts as IUserTeam[];
        if (!payload.accountId) {
          errorHandler({ error: "No user id specified" });
          return;
        }

        if (payload.setUser) {
          const user: IUser = {
            api_key: payload.apiKey ? payload.apiKey : "",
            name: data.name ? data.name : "",
            id: data._id ? data._id : "",
            organization_id: data.organization_id ? data.organization_id : "",
            email: data.email ? data.email : "",
            creation_time: data.creation_time ? data.creation_time : "",
            job_title: data.job_title ? data.job_title : "",
          };
          const cookies = $cookies;
          if (cookies) {
            const loggedinuser: IUser = {
              api_key: user.api_key ? user.api_key : "",
              id: user.id ? user.id : "",
            };
            cookies.set("loggedinuser", loggedinuser);
          }
          store.commit("User", user);
        } else {
          try {
            if (data.creation_time && store.state.user && !store.state.user.creation_time && (window as any).mixpanel) {
              (window as any).mixpanel.app.people.set("Creation_time", data.creation_time);
              (window as any).mixpanel.app.identify();
            }
          } catch (e: any) {
            console.error("Unknown analytics failure " + e);
          }
        }
        store.commit("Teams", teams);
        store.commit("EnlyftAdmin", is_enlyft_admin);
        resolve(teams);
      };
      const errorHandler: (error: object) => void = (error: object) => {
        console.error("fetching user teams failed", error);
        reject(error);
      };
      // todo: if we already have the teams in the store, don't call the api
      api.getUserTeams(payload.accountId).then(successHandler).catch(errorHandler);
    });
  },
  setuserTeams(store: ActionContext<ILoginState, any>, teams: IUserTeam[]): void {
    store.commit("Teams", teams);
  },
};

const mutations: MutationTree<ILoginState> = {
  UserName(state: ILoginState, username: string): void {
    state.username = username;
    // ensure reactivity by calling Vue.set, or make sure the initial state has the field initialized (even undefined)
    // state.username = username;
  },
  Redirect(state: ILoginState, redirect: object): void {
    state.redirect = redirect;
  },
  IsSSO(state: ILoginState, flag: boolean): void {
    state.isSSO = flag;
  },
  User(state: ILoginState, user: IUser): void {
    state.user = user;
  },
  Teams(state: ILoginState, teams: IUserTeam[]): void {
    state.teams = teams;
  },
  EnlyftAdmin(state: ILoginState, isEnlyftAdmin: boolean): void {
    const user = state.user;
    if (user) {
      user.is_enlyft_admin = isEnlyftAdmin;
    }
  },
  LoginLoading(state: ILoginState, flag: boolean): void {
    state.loginLoading = flag;
  },
};

const getters: GetterTree<ILoginState, IRootState> = {
  getUser(state: ILoginState): User | null {
    const { user } = state;
    if (!user) {
      return null;
    }
    let u: User | null;
    try {
      u = new User(user);
    } catch (err) {
      console.error("not User:", user);
      u = null;
    }
    return u;
  },
  apiToken(state: ILoginState): string | null {
    const { user } = state;
    if (!user) {
      return null;
    }
    const token: string = "Basic " + new Buffer(user.id + ":" + user.api_key).toString("base64");
    return token;
  },
  jwtToken(state: ILoginState): string | null {
    const { user } = state;
    if (!user) {
      return null;
    }
    return user.jwt;
  },
  accountId(state: ILoginState): string | null {
    const { user } = state;
    if (!user || !user.id) {
      return null;
    }
    return user.id;
  },
  isLoginLoading(state: ILoginState): boolean | null {
    const { loginLoading } = state;
    return loginLoading || null;
  },
};

const namespaced: boolean = true;

export const loginStore: Module<ILoginState, IRootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
