import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import store from "../store";
import router from "../router";
import utils from "@/shared/utils";

export default function setup() {
  axios.interceptors.request.use(
    (config: AxiosRequestConfig) => {
      const activeTeamId = store.getters["team/activeTeamId"] || localStorage.getItem("teamId");
      const jwtAuth: string | null = localStorage.getItem(`jwt-team-${activeTeamId}`) ? localStorage.getItem(`jwt-team-${activeTeamId}`) : localStorage.getItem("jwt-auth");

      if (jwtAuth && config?.headers) {
        config.headers["X-Auth-Token"] = jwtAuth;
        return config;
      } else if (
        config.url &&
        (config.url === "https://api.idatalabs.com/v1/token" ||
          config.url === "https://api.idatalabs.com/v1/request-password-token" ||
          config.url === "https://api.idatalabs.com/v1/set-password" ||
          config.url === "https://api.idatalabs.com/v1/verify-password-token" ||
          config.url === utils.MODEL_API + "v2/users/signup/" ||
          config.url === utils.MODEL_API + "v2/users/verify_email/" ||
          config.url.endsWith("release.txt") ||
          config.url.includes("jwt") ||
          config.url.includes("organizations"))
      ) {
        return config;
      } else {
        console.error("request unauthorized", config);
        store.dispatch("setClearStorage", true).then(() => {
          store.dispatch("setClearStorage", false).then(() => {
            store.dispatch("login/logout").then(() => {
              router.push({
                name: "loginEmail",
              });
            });
          });
        });
        throw new axios.Cancel("unauthorized");
      }
    },
    (error: any) => {
      return Promise.reject(error);
    },
  );
  axios.interceptors.response.use((response: AxiosResponse) => {
    if (response && response.data && response.data.internal_code) {
      if (response.data.internal_code === 472) {
        const originalRequest = response;
        return refreshTokenAndRetryRequest(originalRequest).catch((data) => {
          console.info("Auth expired", data);
          return response;
        });
      } else if (response.data.internal_code === 471 || response.data.internal_code === 474) {
        store.dispatch("setClearStorage", true).then(() => {
          store.dispatch("setClearStorage", false).then(() => {
            store.dispatch("login/logout").then(() => {
              router.push({
                name: "loginEmail",
              });
            });
          });
        });

        return Promise.reject(new Error("Auth Expired"));
      } else if (response.data.internal_code > 472) {
        const errMessage: string = response.data.message ? response.data.message : "";
        store.dispatch("shared/error", { type: "network", exception: errMessage });
      }
    }
    try {
      setCookies();
    } catch (err) {
      console.error("Error checking cookie status", err);
    }
    return response;
  });
  axios.interceptors.request.use((config: AxiosRequestConfig) => {
    if (config?.headers) {
      config.headers["X-App-Name"] = "platform";
      config.headers["X-App-Version"] = document.body.dataset.build || "";
    }

    return config;
  });
}

async function refreshTokenAndRetryRequest(originalResponse: AxiosResponse): Promise<AxiosResponse> {
  try {
    const cookies = $cookies;
    const response: AxiosResponse = originalResponse;
    let userId = store.getters["login/accountId"];
    let teamId = store.getters["team/activeTeamId"] || localStorage.getItem("teamId");
    if (cookies && (!userId || !teamId)) {
      if (!userId) {
        userId = cookies.get("user");
      }
      if (!teamId) {
        teamId = cookies.get("teamid") || cookies.get("teamId");
      }
    }
    const resetToken = await refreshJWT(userId, teamId);
    if (!resetToken) {
      console.error("getJWT error", "refreshTokenAndRetryRequest");
      return Promise.reject(new Error("refreshTokenAndRetryRequest"));
    }
    return new Promise((resolve) => {
      if (response?.config?.headers) response.config.headers["X-Auth-Token"] = resetToken as any;
      resolve(axios.request(response.config));
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

function refreshJWT(userId: string | null, teamId?: string | null): Promise<object> {
  const cookies = $cookies;
  return new Promise((resolve, reject) => {
    const successHandler: (data: any) => void = (data: any) => {
      if (data && data.data && data.data.jwt) {
        if (teamId) utils.setStorage(`jwt-team-${teamId}`, "jwt-token " + data.data.jwt);
        if (cookies) {
          const user = store.getters["login/getUser"];
          if (userId !== null) {
            cookies.set("user", userId);
          }
          if (teamId) {
            cookies.set("teamid", teamId);

            // we have a header/cookie limit set on server side, so we limit JWT cookies to 1 to be safe under the limit of 8KB
            utils.clearTeamTokenCookies();

            cookies.set(`jwt-team-${teamId}`, "jwt-token " + data.data.jwt);
          } else {
            cookies.set("jwt-auth", "jwt-token " + data.data.jwt);
          }
          if (user && user.id && user.api_key) {
            const loggedinuser: any = {
              api_key: user.api_key,
              id: user.id,
            };
            cookies.set("loggedinuser", loggedinuser);
          }
        }
        resolve(data.data.jwt);
        return;
      }
      console.error("getJWT error", "empty response", data);
      reject(new Error("empty response"));
    };
    if (userId && teamId) {
      axios
        .get(utils.MODEL_API + "v2/users/" + userId + "/teams/" + teamId + "/jwt/refresh/")
        .then((data) => {
          if (cookies) {
            cookies.set("teamid", teamId);

            // we have a header/cookie limit set on server side, so we limit JWT cookies to 1 to be safe under the limit of 8KB
            utils.clearTeamTokenCookies();

            cookies.set(`jwt-team-${teamId}`, "jwt-token " + data.data.jwt);
          }
          successHandler(data);
        })
        .catch(utils.genericErrorHandler(resolve, reject));
    } else {
      // if there is no team id just log out - we assume we must have team id along with user id
      if (!userId) console.error(`Missing userId`);
      if (!teamId) console.error(`Missing teamId`);
      store.dispatch("login/logout").then(() => {
        router.push({
          name: "loginEmail",
        });
      });
    }
  });
}

function setCookies() {
  const cookies = $cookies;
  if (cookies) {
    const loggedinuser = cookies.get("loggedinuser");
    const teamId = cookies.get("teamid");
    const modelId = cookies.get("modelId");
    const jwt = cookies.get(`jwt-team-${teamId}`);

    if (!loggedinuser) {
      const user = store.getters["login/getUser"];
      if (user && user.id && user.api_key) {
        const userObj = {
          api_key: user.api_key,
          id: user.id,
        };
        cookies.set("loggedinuser", userObj);
      }
    }

    if (!jwt) {
      const jwtAuth: string | null = localStorage.getItem(`jwt-team-${teamId}`);
      if (jwtAuth) {
        // we have a header/cookie limit set on server side, so we limit JWT cookies to 1 to be safe under the limit of 8KB
        utils.clearTeamTokenCookies();

        cookies.set(`jwt-team-${teamId}`, jwtAuth);
      }
    }
    if (!teamId) {
      const teamId_store = store.getters["team/activeTeamId"];
      if (teamId_store) {
        cookies.set("teamid", teamId_store);
      }
    }
    if (!modelId) {
      const modelId_store = store.getters["model/activeModelId"];
      if (modelId_store) {
        cookies.set("modelId", modelId_store);
      }
    }
  }
}
